import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Drawer,
  List,
  ListItem,
  Box,
  Popover,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../../src/images/logo.png";
import { useAuth } from "../../context/Auth";
import "./Header.css";

const Header = () => {
  const { auth, setAuth } = useAuth(); // Corrected destructuring
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(false);

  const timeoutId = useRef(null);
  let timer;

  // const handleMenuOpen = (event, type) => {
  //   // Clear any existing timer
  //   setAnchorEl(event.currentTarget);
  //   setMenuType(type);
  //   setOpen(true);
  // };
  const handleMenuClose = () => {
    // timer = setTimeout(() => {
    //   setOpen(false);
    //   setAnchorEl(null);
    //   setMenuType(null);
    // }, 2000);
  };

  const handleMenuOpen = (event, type) => {
    // Check if clicking the same button should close the dropdown
    if (menuType === type && open) {
      setOpen(false);
      setAnchorEl(null);
      setMenuType(null);
    } else {
      // Open dropdown for the clicked item
      setAnchorEl(event.currentTarget);
      setMenuType(type);
      setOpen(true);
    }
  };

  const handleButtonMouseLeave = () => {
    // Delay closing to allow for smoother hover experience
    setTimeout(() => {
      setOpen(false);
      setAnchorEl(null);
      setMenuType(null);
    }, 2000);
  };
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };

  const [openDropdowns, setOpenDropdowns] = useState({
    about: false,
    studentInfo: false,
    profile: false,
    visas: false,
  });
  const [openSubmenus, setOpenSubmenus] = useState({});
  const dropdownRefs = {
    about: useRef(null),
    studentInfo: useRef(null),
    profile: useRef(null),
    visas: useRef(null),
  };

  // Toggle specific dropdown based on key
  const toggleDropdown = (dropdown) => {
    setOpenDropdowns((prev) => ({
      [dropdown]: !prev[dropdown],
    }));
  };

  // Toggle specific submenu within "Visas"
  const toggleSubmenu = (submenu) => {
    setOpenSubmenus((prev) => ({
      [submenu]: !prev[submenu],
    }));
  };

  // Close specific dropdown when clicking outside
  const handleClickOutside = (event) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(event.target)
      ) {
        setOpenDropdowns((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
    });
    // Close submenus when clicking outside
    setOpenSubmenus({});
  };

  // Close all dropdowns when drawer is closed
  useEffect(() => {
    if (!drawerOpen) {
      setOpenDropdowns({
        about: false,
        studentInfo: false,
        profile: false,
        visas: false,
      });
      setOpenSubmenus({});
    }
  }, [drawerOpen]);

  useEffect(() => {
    // Add event listener to close dropdowns when clicking outside
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        display: { xs: "block", sm: "block", md: "block", lg: "none" },
      }}
      role="presentation"
      onClick={(event) => event.stopPropagation()}
    >
      <List>
        {/* About Us Dropdown */}
        <div className="dropdown-container" ref={dropdownRefs.about}>
          <label
            className="dropdown-label"
            onClick={() => toggleDropdown("about")}
          >
            <Button className="nav-button" sx={{ color: "black", mt: 2 }}>
              About Us <ExpandMoreIcon sx={{ fontSize: 17 }} />
            </Button>
          </label>

          <div
            className={`dropdown-content ${openDropdowns.about ? "open" : ""}`}
          >
            <ul>
              <li>
                <NavLink to="/about" onClick={() => toggleDropdown("about")}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" onClick={() => toggleDropdown("about")}>
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/team" onClick={() => toggleDropdown("about")}>
                  Our Team
                </NavLink>
              </li>
              <li>
                <NavLink to="/services" onClick={() => toggleDropdown("about")}>
                  Services
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="dropdown-container" ref={dropdownRefs.visas}>
          <label
            className="dropdown-label"
            onClick={() => toggleDropdown("visas")}
          >
            <Button className="nav-button" sx={{ color: "black" }}>
              Visas <ExpandMoreIcon sx={{ fontSize: 17 }} />
            </Button>
          </label>

          <div
            className={`dropdown-content ${openDropdowns.visas ? "open" : ""}`}
          >
            <ul>
              {/* Skilled Visas Submenu */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("skilledVisas")}
                >
                  Skilled Visas <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.skilledVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <NavLink to="/skilledindependent-visa-189">
                      Skilled Independent (Subclass 189)
                    </NavLink>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-nominated-visa-190">
                      Skilled Nominated (Subclass 190)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-work-visa-491">
                      Skilled Work Regional (Subclass 491)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/permanent-residence-visa-191">
                      Permanent Residence Regional (Subclass 191)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-residence-visa-887">
                      Skilled Residence (Subclass 887)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Temporary Active Visas Submenu */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("temporaryVisas")}
                >
                  Temporary Active Visas{" "}
                  <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.temporaryVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/temporary-activity-408-visa">
                      Temporary Activity (Subclass 408)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/short-stay-visa-400">
                      Temporary Work Visa (Subclass 400)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Employer Sponsored Visas Submenu */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("employerSponsoredVisas")}
                >
                  Employer Sponsored Visas{" "}
                  <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.employerSponsoredVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/temporary-skills-shortage-visa-482">
                      Temporary Skill Shortage (Subclass 482)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/employer-nomination-186-visa">
                      Employer Nomination (Subclass 186)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-employer-sponsored-regional-494-visa">
                      Skill Employer Sponsored Regional (Subclass 494)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Graduate Visas Submenu */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("graduateVisas")}
                >
                  Graduate Visas{" "}
                  <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.graduateVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-graduate-485-visa">
                      Skilled Temporary Graduate (Subclass 485)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/skilled-recognised-graduate-476-visa">
                      Skilled Recognised Graduate (Subclass 476)
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Visitor Visas */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("visitorVisas")}
                >
                  Visitor Visas <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.visitorVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/visitor-visa-600">Visitor (Subclass 600)</Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/electronic-travel-authority-visa-601">
                      Electronic Travel Authority (Subclass 601)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/evisitor-visa-651">eVisitor (Subclass 651)</Link>
                  </li>
                </ul>
              </li>

              {/* Tourist Visas */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("touristVisas")}
                >
                  Tourist Visas <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.touristVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/work-and-holiday-visa-462">
                      Work and Holiday (Subclass 462)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/working-holiday-visa-417">
                      Working Holiday (Subclass 417)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Student & Training Visas */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("studentTrainingVisas")}
                >
                  Student & Training Visas{" "}
                  <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.studentTrainingVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/australia-student-visa-500">
                      Student (Subclass 500)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/training-visa-subclass-407">
                      Training (Subclass 407)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Partner Visas */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("partnerVisas")}
                >
                  Partner Visas <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.partnerVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/onshore-partner-visa-subclass-820-801">
                      Onshore Partner (Subclass 820/801)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/offshore-partner-visa-309-100">
                      Offshore Partner (Subclass 309/100)
                    </Link>
                  </li>
                  <li style={{ fontSize: "13px" }}>
                    <Link to="/prospective-marriage-visa-300">
                      Prospective Marriage (Subclass 300)
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Parents Visas */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("parentsVisas")}
                >
                  Parents Visas <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.parentsVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/parent-visa-103">Parent (Subclass 103)</Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/aged-parent-visa-804">
                      Aged Parent (Subclass 804)
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/contributory-parent-visa-143">
                      Contributory Parent (Subclass 143)
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/contributory-parent-visa-173">
                      Contributory Parent (Subclass 173)
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/contributory-aged-parent-visa-864">
                      Contributory Aged Parent (Subclass 864)
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/contributory-aged-parent-visa-884">
                      Contributory Aged Parent (Subclass 884)
                    </Link>
                  </li>
                </ul>
              </li>

              {/*Appeals, Review and Cancellations */}
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleSubmenu("appealsVisas")}
                >
                  Appeals, Review and Cancellations{" "}
                  <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                </div>
                <ul
                  className={`submenu1 ${
                    openSubmenus.appealsVisas ? "open" : ""
                  }`}
                >
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/administrative-appeals-tribunal-aat">AAT</Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/notice-of-intention-to-consider-cancellation-noicc">
                      NOICC
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <Link to="/cancel-a-visa">Visa Cancellation</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* Student Info Hub Dropdown */}
        <div className="dropdown-container" ref={dropdownRefs.studentInfo}>
          <label
            className="dropdown-label"
            onClick={() => toggleDropdown("studentInfo")}
          >
            <Button className="nav-button" sx={{ color: "black" }}>
              Student Info Hub <ExpandMoreIcon sx={{ fontSize: 17 }} />
            </Button>
          </label>

          <div
            className={`dropdown-content ${
              openDropdowns.studentInfo ? "open" : ""
            }`}
          >
            <ul>
              <li>
                <NavLink to="/#" onClick={() => toggleDropdown("studentInfo")}>
                  Action
                </NavLink>
              </li>
              <li>
                <NavLink to="/#" onClick={() => toggleDropdown("studentInfo")}>
                  Another Action
                </NavLink>
              </li>
              <li>
                <NavLink to="/#" onClick={() => toggleDropdown("studentInfo")}>
                  Another Action
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <ListItem button component={NavLink} to="/blogs">
          <Button
            sx={{ color: "black" }}
            component={NavLink}
            to="/jobs-and-career"
          >
            Jobs & Career
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/apply-visa">
          <Button sx={{ color: "black" }} component={NavLink} to="/apply-visa">
            Apply Visa
          </Button>
        </ListItem>
        <ListItem button component={NavLink}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#cb0731" }}
            component={NavLink}
            to="/appointment"
          >
            Appointment
          </Button>
        </ListItem>
        <ListItem component={NavLink} to="/">
          <Button component={NavLink} to="/search" sx={{ color: "black" }}>
            Search
          </Button>
        </ListItem>

        {/* Profile Dropdown */}
        <div className="dropdown-container" ref={dropdownRefs.profile}>
          <label
            className="dropdown-label"
            onClick={() => toggleDropdown("profile")}
          >
            <Button className="nav-button" sx={{ color: "black" }}>
              Profile <ExpandMoreIcon sx={{ fontSize: 17 }} />
            </Button>
          </label>

          <div
            className={`dropdown-content ${
              openDropdowns.profile ? "open" : ""
            }`}
          >
            <ul>
              <MenuItem className="menu-item" component={NavLink} to="/account">
                Account
              </MenuItem>
              {!auth?.user ? (
                <>
                  <MenuItem
                    className="menu-item"
                    component={NavLink}
                    to="/login"
                  >
                    Login
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    component={NavLink}
                    to="/register"
                  >
                    Signup
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem
                    onClick={handleLogout}
                    className="menu-item"
                    component={NavLink}
                    to="/login"
                  >
                    Logout
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    component={NavLink}
                    to={`/dashboard/${auth.user.role === 1 ? "admin" : "user"}`}
                  >
                    Dashboard
                  </MenuItem>
                </>
              )}
            </ul>
          </div>
        </div>

        {/* Visas Dropdown */}
      </List>
    </Box>
  );

  // Updated functions

  return (
    <AppBar position="sticky" className="header">
      <Toolbar
        className="toolbar"
        sx={{ backgroundColor: "white", height: "90px", zIndex: 1201 }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            mr: 5,
          }}
        >
          <NavLink to="/">
            <img
              src={logo}
              alt="Real Migration Logo"
              style={{ height: "55px", width: "100%" }}
            />
          </NavLink>
        </Box>
        {isMobile ? (
          <>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <NavLink to="/">
              <img
                src={logo}
                alt="Real Migration Logo"
                style={{ height: "55px", width: "100%" }}
              />
            </NavLink>
            <IconButton sx={{ color: "black" }} onClick={toggleSearch}>
              {<SearchIcon />}
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "none" },
              }}
            >
              <Button
                className="nav-button"
                sx={{ color: "black" }}
                component={NavLink}
                onMouseOver={(e) => handleMenuOpen(e, "profile")}
                onMouseLeave={handleButtonMouseLeave}
              >
                <PersonIcon /> <ExpandMoreIcon />
              </Button>
            </Box>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
              {drawerContent}
            </Drawer>
          </>
        ) : (
          <React.Fragment>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
                flexWrap: "wrap",
              }}
            >
              <div className="nav-links">
                <Typography
                  className="nav-button"
                  component={NavLink}
                  to="/search"
                  sx={{ color: "black" }}
                >
                  Search
                </Typography>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    About Us <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-about">
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/team">Our Team</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services">Services</NavLink>
                    </li>
                  </ul>
                </div>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Visas <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <div id="submenu-visas" className="visas-menu">
                    <ul className="menu-item-li">
                      <li className="menu-item-li-a">
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Skilled Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilledindependent-visa-189"
                            >
                              Skilled Independent (Subclass 189)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-nominated-visa-190"
                            >
                              Skilled Nominated (Subclass 190)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-work-visa-491"
                            >
                              Skilled Work Regional (Subclass 491)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/permanent-residence-visa-191"
                            >
                              Permanent Residence Regional (Subclass 191)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-residence-visa-887"
                            >
                              Skilled Residence (Subclass 887)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Temporary Active Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />{" "}
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/temporary-activity-408-visa"
                            >
                              Temporary Activity (Subclass 408)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/short-stay-visa-400"
                            >
                              Temporary Work Visa(Subclass 400)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Employer Sponsored Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/temporary-skills-shortage-visa-482"
                            >
                              Temporary Skill Shortage (Subclass 482)
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/employer-nomination-186-visa"
                            >
                              Employer Nomination (Subclass 186)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-employer-sponsored-regional-494-visa"
                            >
                              Skill Employer Sponsored Regional (Subclass 494)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Graduate Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-graduate-485-visa"
                            >
                              Skilled Temporary Graduate (Subclass 485)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-recognised-graduate-476-visa"
                            >
                              Skilled Recognised Graduate (Subclass 476)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Visitor Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/visitor-visa-600"
                            >
                              Visitor (Subclass 600)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/electronic-travel-authority-visa-601"
                            >
                              Electronic Travel Authority (Subclass 601)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/evisitor-visa-651"
                            >
                              eVisitor (Subclass 651)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Tourist Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/work-and-holiday-visa-462"
                            >
                              Work and Holiday (Subclass 462)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/working-holiday-visa-417"
                            >
                              Working Holiday (Subclass 417)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Student & Training Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/australia-student-visa-500"
                            >
                              Student (Subclass 500)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/training-visa-subclass-407"
                            >
                              Training (Subclass 407)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Partner Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/onshore-partner-visa-subclass-820-801"
                            >
                              Onshore Partner (Subclass 820/801)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/offshore-partner-visa-309-100"
                            >
                              Offshore Partner (Subclass 309/100)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/prospective-marriage-visa-300"
                            >
                              Prospective Marriage (Subclass 300)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Parents Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/parent-visa-103"
                            >
                              Parent (Subclass 103)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/aged-parent-visa-804"
                            >
                              Aged Parent (Subclass 804)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-parent-visa-143"
                            >
                              Contributory Parent (Subclass 143)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-parent-visa-173"
                            >
                              Contributory Parent (Subclass 173)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-aged-parent-visa-864"
                            >
                              Contributory Aged Parent (Subclass 864)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-aged-parent-visa-884"
                            >
                              Contributory Aged Parent (Subclass 884)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Appeals, Review and Cancellations{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/administrative-appeals-tribunal-aat"
                            >
                              AAT
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/notice-of-intention-to-consider-cancellation-noicc"
                            >
                              NOICC
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/cancel-a-visa"
                            >
                              Visa Cancellation
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Student Info Hub <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-student">
                    <li>
                      <a href="#">Action</a>
                    </li>
                    <li>
                      <a href="#">Another action</a>
                    </li>
                    <li>
                      <a href="#">Something else here</a>
                    </li>
                  </ul>
                </div>

                <Typography
                  className="nav-button"
                  sx={{ color: "black" }}
                  component={NavLink}
                  to="/jobs-and-career"
                >
                  Jobs & Career
                </Typography>
                <Typography
                  className="nav-button"
                  sx={{ color: "black" }}
                  component={NavLink}
                  to="/apply-visa"
                >
                  Apply Visa
                </Typography>
                <Typography
                  className="nav-button"
                  variant="contained"
                  sx={{
                    backgroundColor: "#cb0731",
                    border: "solid",
                    borderColor: "white",
                    padding: "10px",
                    borderRadius: 1,
                    color: "white ",

                    mr: 3,
                  }}
                  component={NavLink}
                  to="/appointment"
                >
                  Appointment
                </Typography>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Profile <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-profile">
                    <MenuItem
                      className="menu-item"
                      component={NavLink}
                      to="/account"
                    >
                      Account
                    </MenuItem>
                    {!auth.user ? (
                      <>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to="/login"
                        >
                          Login
                        </MenuItem>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to="/register"
                        >
                          Signup
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem
                          onClick={handleLogout}
                          className="menu-item"
                          component={NavLink}
                          to="/login"
                        >
                          Logout
                        </MenuItem>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to={`/dashboard/${
                            auth?.user?.role === 1 ? "admin" : "user"
                          }`}
                        >
                          Dashboard
                        </MenuItem>
                      </>
                    )}
                  </ul>
                </div>

                <IconButton sx={{ color: "black" }} onClick={toggleSearch}>
                  {<SearchIcon />}
                </IconButton>
              </div>
            </Box>{" "}
          </React.Fragment>
        )}
      </Toolbar>
      {isSearchOpen && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <div className="search-bar">
            <input
              type="text"
              className="form-control search-bar-text"
              placeholder="Search here -----------"
            />
          </div>
          <IconButton
            sx={{ color: "black", backgroundColor: "white" }}
            onClick={toggleSearch}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </AppBar>
  );
};

export default Header;
