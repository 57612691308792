import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";

export default function BookAnAppointment() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    // Add the external script for the widget
    const scriptId = "form-embed-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://link.msgsndr.com/js/form_embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "linear-gradient(135deg, #f9f9f9, #f1f1f1)",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        {/* Show a loader while the iframe is loading */}
        {!iframeLoaded && (
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              color: "#888",
              marginBottom: "20px",
            }}
          >
            Loading, please wait...
          </div>
        )}
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            background: "white",
            borderRadius: "15px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            src="https://api.leadconnectorhq.com/widget/group/3Sl5N3yGnyM2lRe1KMtB"
            style={{
              width: "100%",
              height: "90vh", // Default height for larger screens
              border: "none",
              borderRadius: "10px",
              transition: "opacity 0.3s ease-in-out", // Add a smooth transition
              opacity: iframeLoaded ? 1 : 0, // Show iframe when loaded
            }}
            onLoad={handleIframeLoad}
            id="appointment-iframe"
            title="Book an Appointment"
          ></iframe>
        </div>
      </div>
      <style>
        {`
          @media (max-width: 1024px) {
            #appointment-iframe {
              height: 80vh; /* Adjust height for medium screens like tablets */
            }
          }

          @media (max-width: 768px) {
            #appointment-iframe {
              height: 75vh; /* Adjust height for mobile devices */
              border-radius: 8px; /* Slightly smaller border radius */
            }

            div[style*="max-width: 1200px"] {
              max-width: 90%; /* Adjust width for smaller screens */
            }
          }

          @media (max-width: 576px) {
            #appointment-iframe {
              height: 70vh; /* Smaller height for small mobile devices */
            }

            div[style*="max-width: 1200px"] {
              max-width: 95%; /* Narrower width for very small screens */
              padding: 10px; /* Reduce padding */
            }
          }
        `}
      </style>
    </Layout>
  );
}
